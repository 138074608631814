import { type ParsedUrlQuery } from 'querystring';
import React from 'react';
import { MarketPickerModal } from 'bb/app/nav/MarketPicker';
import { Head } from 'bb/common/head/Head';
import { getServerSideTranslations } from 'bb/i18n/resources/server/getServerSideTranslations';
import { setCacheControlHeader } from 'bb/page/server/createServerSideProps';
import type { GetServerSidePropsContext, PreviewData } from 'next/types';

const MarketPage = () => {
    return (
        <>
            <Head noIndex />
            <MarketPickerModal
                buttonProps={{ 'aria-label': 'close' }}
                limitHeight
                isOpen
            />
        </>
    );
};

export const getServerSideProps = async (
    ctx: GetServerSidePropsContext<ParsedUrlQuery, PreviewData>
) => {
    /* Use 'en' locale for this page since we don't have a market in path, and we want to cache this */
    const locale = 'en';
    const translations = await getServerSideTranslations(locale, [
        'nav',
        'countries'
    ]);

    setCacheControlHeader(ctx, { maxAge: 600 });

    return {
        props: translations
    };
};

export default MarketPage;
